#homepage-slider {
  overflow: hidden;
  float: left;
  position: relative;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}
#homepage-slider #homeslider {
  margin: 0px auto;
  padding: 0px 0px 0px 0px;
  max-height: 496px;
  visibility: hidden;
}
#homepage-slider .bx-wrapper {
  margin-bottom: 60px;
}
#homepage-slider .bx-wrapper #homeslider {
  visibility: visible;
}
#homepage-slider .bx-wrapper #homeslider .homeslider-container a img {
  height: auto;
}
#homepage-slider .homeslider-container {
  position: relative;
}
#homepage-slider .bx-wrapper a {
  display: block;
}
#homepage-slider .bx-wrapper img {
  height: auto;
}
#homepage-slider .bx-wrapper .bx-viewport {
  width: 100%;
  direction: ltr !important;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-prev, 
#homepage-slider .bx-wrapper .bx-controls-direction .bx-next {
  position: absolute;
  top: auto;
  margin-top: 0;
  font-size: 1em;
  bottom: 10px;
  outline: 0;
  width: 52px;
  height: 52px;
  text-indent: -9999px;
  z-index: 28;
  border: 2px solid #F4A700;
  line-height: 18px;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-prev:before, 
#homepage-slider .bx-wrapper .bx-controls-direction .bx-next:before {
  height: 48px;
  width: 48px;
  display: block;
  background: #fff;
  content: ".";
  opacity: 0.14;
  text-indent: -9999px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-prev:after, 
#homepage-slider .bx-wrapper .bx-controls-direction .bx-next:after {
  display: block;
  width: 48px;
  height: 48px;
  color: #F4A700;
  text-indent: 0;
  text-align: center;
  font-size: 19px;
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding: 16px 0px 0px 0px;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-prev {
  right: 60px;
  left: auto;
  background: none;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-next {
  right: 10px;
  background: none;
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-prev:after {
  content: "\f053";
}
#homepage-slider .bx-wrapper .bx-controls-direction .bx-next:after {
  content: "\f054";
}
@media (max-width: 768px) {
  #homepage-slider {
    padding: 20px 0px 0px 0px;
	width: 100%;
    max-width: 100%;
  }
  #homepage-slider #homeslider {
    width: 719px;
    height: 261px;
  }
  #homepage-slider .bx-wrapper {
    margin-bottom: 30px;
  }
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-prev, 
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-next {
    width: 35px;
    height: 35px;
  }
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-prev:before, 
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-next:before {
    width: 31px;
    height: 31px;
  }
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-prev:after, 
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-next:after {
    width: 31px;
    height: 31px;
	padding: 9px 0px 0px 0px;
  }
  #homepage-slider .bx-wrapper .bx-controls-direction .bx-prev {
   right: 43px;
  }
}
@media (max-width: 480px) {
  #homepage-slider #homeslider {
    width: 433px;
    height: 157px;
  }
}