#manufacturer-bx-wrapper .brands-title {
  margin: 5px 0px 5px 0px;
  padding: 25px 0px 0px 0px;
  color: #212121;
  line-height: 1.1;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
}
#manufacturer-bx-wrapper .bx-wrapper {
  margin-bottom: 0px;
  border: none;
  background: transparent;
  box-shadow: none;
}
#manufacturer-bx-wrapper #manufactures li {
  position: relative;
  padding-bottom: 0;
  filter: grayscale(100%);
}
#manufacturer-bx-wrapper #manufactures li:hover {
  padding-bottom: 0;
  filter: grayscale(0);
}
#manufacturer-bx-wrapper #manufactures li a {
  display: inline-block;
  height: 98px;
  width: 98px;
  filter: grayscale(100%);
}
#manufacturer-bx-wrapper #manufactures li a:hover {
  filter: grayscale(0%);
}