#search_block_top {
  padding: 5px 10px 0px 10px;
  height: 46px;
}
#search_block_top form {
  position: relative;
  float: left;
  padding-top: 5px;
  width: 100%;
}
#search_block_top form #search_query_top {
  display: inline;
  margin-right: 1px;
  padding: 0 13px;
  height: 36px;
  width: 100%;
  border: 1px solid #DFD8CA;
  border-right: 0 !important;
  color: #212121;
  background: #FFFFFF;
  background: url('/modules/blocksearch/img/bg_search_input.png') repeat-x 0 0 #FFFFFF;
  float: left;
}
#search_block_top form button {
  background: #F4A700;
  display: block;
  position: absolute;
  top: 5px;
  right: 0px;
  border: none;
  margin: 0px 0px 0px 0px;
  color: #FFFFFF;
  width: 36px;
  height: 36px;
  text-align: center;
  padding: 5px 0px 11px 0px;
}
#search_block_top form button:before {
  content: "\f002";
  display: block;
  font-family: "FontAwesome";
  font-size: 17px;
  width: 100%;
  text-align: center;
}
#search_block_top form button:hover {
  background: #2B2B2B;
  color: #6F6F6F;
}
#search_block_top form button span {
  display: none;
}
.ac_results {
  background: #FFFFFF;
  border: 1px solid #D6D4D4 !important;
  width: 271px;
  margin-top: -1px;
}
.ac_results li {
  padding: 0 10px;
  font-weight: normal;
  color: #212121;
  font-size: 13px;
  line-height: 22px;
}
.ac_results li.ac_odd {
  background: #FFFFFF;
}
.ac_results li:hover {
  background: #FBFBFB;
}
.ac_results li.ac_over {
  background: #FBFBFB;
}
@media (max-width: 768px) {
  #search_block_top {
    position: fixed;
    top: 51px;
    width: 100%;
    display: none;
    height: calc(100% - 50px);
    background-color: #FFFFFF;
    padding: 5px 10px 50px 10px;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
  .ac_results::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 0px;
    background-color: #F5F5F5;
  }
  .ac_results::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }
  .ac_results::-webkit-scrollbar-thumb {
    border-radius: 0px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #6F6F6F;
  }
  .ac_results .ac_odd {
    width: calc(100% - 1px);
  }
  .ac_results .ac_over {
    width: calc(100% - 1px);
  }
}