.header_user_info {
  float: right;
  border-left: 0 solid #212121;
  border-right: 0 solid #212121;
}
.header_user_info a {
  float: left;
  color: #212121;
  font-weight: 600;
  display: block;
  padding: 10px;
  cursor: pointer;
  line-height: 18px;
}
@media (max-width: 768px) {
  .header_user_info {
    display: none;
  }
}
@media (max-width: 480px) {
  .header_user_info a {
    font-size: 11px;
  }
}