.icon-diamond:before {
  content: "\f219";
}
.icon-truck:before {
  content: "\f0d1";
}
.icon-lock:before {
  content: "\f023";
}
.icon-phone:before {
  content: "\f095";
}