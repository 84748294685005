#SearchResultsNone {
  margin: 0px 0px 0px 0px;
  padding: 15px 15px 15px 15px;
  width: 100%;
  height: 50px;
}
/* porównywanie produktów start */
.compare-form button {
  display: block;
  margin: -1px 1px 0px 0px;
  border: none;
  padding: 0px 0px 0px 0px !important;
  outline: none !important;
  height: 24px !important;
  line-height: normal !important;
  background: transparent !important;
  cursor: pointer !important;
  color: #F4A700 !important;
  font-size: 18px !important;
}
.compare-form button:hover {
  padding: 0px 0px 0px 0px !important;
  background: transparent !important;
  opacity: 0.7 !important;
}
.compare-form button i {
  margin: 0px 0px 0px 5px !important;
  font-size: 18px;
}
.compare-form button i:before {
  content: "\f24e";
}
/* porównywanie produktów koniec */
ul.product_list {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
}
ul.product_list > li {
  position: relative;
  top: 0px;
  left: 0px;
  margin: 5px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  background-color: #FFFFFF;
}
ul.product_list > li.hovered {
  z-index: 1;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.17);
}
ul.product_list > li .product_image_container {
  display: block;
  position: relative;
  margin: 0px 3px 0px 3px;
  border: solid 1px #FFFFFF;
  padding: 0px 0px 0px 0px; 
  width: calc(100% - 6px);
  background: #FFFFFF;
  text-align: center;
}
ul.product_list > li.hovered .product_image_container {
  border: solid 1px #FFFFFF;
}
/* Ikonka konfiguracji w dolnym prawy rogu na stronie listy produktów - Rudnicki - Start */
ul.product_list > li .product_image_container .cogs {
  position: absolute;
  bottom: 3px;
  right: 3px;
}
ul.product_list > li .product_image_container .cogs img {
  width: 35px;
  height: 35px;
}
ul.product_list > li .product_image_container .cogs .cogs_info {
  position: absolute;
  background: #F4A700;
  color: #FFFFFF;
  padding: 3px 3px 4px;
  left: -95px;
  bottom: 5px;
  width: 95px;
  font-size: 12px;
  line-height: 12px;
  visibility: hidden;
}
ul.product_list > li .product_image_container .cogs:hover .cogs_info {
  visibility: visible;
  opacity: 1;
}
ul.product_list > li .product_image_container .cogs .cogs_info::before {
  width: 4px;
  height: 4px;
  background: #F4A700;
  content: "";
  transform: rotate(45deg);
  right: -2px;
  top: 6px;
  position: absolute;
}
/* Ikonka konfiguracji w dolnym prawy rogu na stronie listy produktów - Rudnicki - Koniec */
ul.product_list > li .product_image_container .product_img_link {
  display: inline-block;
}
ul.product_list > li .product_image_container .product_img_link img {
  margin: 0px auto 0px auto;
  border: none;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: auto;
  min-height: 50px;
}
ul.product_list > li .product_image_container .badge_block {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px 2px 0px 2px;
  width: calc(100% - 4px);
}
ul.product_list > li .product_image_container .badge_block .new-box {
  position: absolute;
  top: 0px;
  right: 1px;
  left: initial;
  overflow: hidden;
  display: inline;
  float: right;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 22px;
  width: 100px;
  text-align: center;
}
ul.product_list > li .product_image_container .badge_block .new-label {
  position: absolute;
  top: 1px;
  right: 0px;
  left: auto;
  overflow: hidden;
  display: inline-block;
  float: right;
  margin: 0px 0px 0px 0px;
  border-radius: 3px / 3px;
  padding: 5px 3px 5px 3px;
  height: 20px;
  width: 100px;
  color: #FFFFFF;
  line-height: 10px;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  text-shadow: 0 0 rgba(0, 0, 0, 0.24);
  text-transform: uppercase;
  transform: none;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY1DLXAYAAe4BNoEuTgcAAAAASUVORK5CYII=') repeat !important;
}
ul.product_list > li .product_image_container .badge_block .sale-label {
  position: relative;
  top: 1px;
  right: auto;
  left: 0px;
  overflow: hidden;
  display: table;
  margin: 0px 0px 3px 0px;
  border: 0px;
  border-radius: 3px / 3px;
  padding: 5px 3px 5px 3px;
  height: 20px;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 10px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXYzgpogQAAqkBAHYzDhoAAAAASUVORK5CYII=') repeat;
}
ul.product_list > li .product_image_container .badge_block .sale-box {
  position: relative;
  top: 1px;
  right: 0px;
  left: 0px;
  overflow: hidden;
  display: table;
  border: 0px;
  margin: 0px 0px 3px 0px;
  border-radius: 3px / 3px;
  padding: 5px 3px 5px 3px;
  height: 20px;
  font-weight: 600;
  font-size: 12px !important;
  line-height: 10px;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXYzgpogQAAqkBAHYzDhoAAAAASUVORK5CYII=') repeat !important;
}
ul.product_list > li .product_name_product_list {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  height: 36px;
  width: 100%;
  color: #212121;
  background-color: #FFFFFF;
  font-size: 14px;
  line-height: 16px;
  font-weight: 200;
  text-transform: none;
}
ul.product_list > li .product_name_product_list .product-name {
  display: block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 2px 2px 0px 2px;
  width: auto;
  min-width: 50px;
  max-width: 100%;
  height: 36px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 200;
  text-decoration: none;
  color: #212121;
}
ul.product_list > li .collection_name_product_list {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 36px;
}
ul.product_list > li .collection_name_product_list .kolekcja-nazwa {
  display: block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 2px 2px 2px 2px;
  width: auto;
  min-width: 50px;
  max-width: 100%;
  height: 36px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
}
ul.product_list > li .content_price {
  height: 40px;
}
ul.product_list > li .content_price .price {
  display: block;
  margin: 0px 0px 2px 0px;
  border: none;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 18px;
  color: #F4A700;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDCQqzU+sfwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/j//z8ABf4C/tzMWecAAAAASUVORK5CYII=');
  clear: both;
}
#categories_scenes #scenes_products_list ul.product_list > li .content_price .price {
  margin: 0px 0px 0px 0px;
  font-size: 15px;
}
ul.product_list > li .content_price .old-price {
  display: inline;
  margin: 0px 5px 0px 0px;
  padding: 1px 0px 0px 0px;
  height: 21px;
  color: #212121;
  font-size: 13px;
  font-weight: 600;
  line-height: 100%;
  text-decoration: line-through;
}
ul.product_list > li .content_price .new-price-discount {
  display: inline;
  border-radius: 3px / 3px;
  margin: 0px 0px 0px 7px;
  padding: 1px 5px 1px 5px;
  height: 19px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 600;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY7BYbAUAAiwBFo8QZgoAAAAASUVORK5CYII=') repeat !important;
}
#categories_scenes #scenes_products_list ul.product_list > li .content_price .new-price-discount {
  font-size: 12px;
}
ul.product_list > li .button-container {
  display: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}
ul.product_list.mobile > li .button-container, 
ul.product_list.tablet > li .button-container {
  display: block;
}
ul.product_list > li.hovered .button-container {
  display: block;
}
ul.product_list > li .button-container p {
  margin: 0px 0px 0px 0px;
}
ul.product_list > li .button-container span.button {
  margin: 0px 0px 0px 0px;
  text-shadow: none;
}
ul.product_list > li .button-container .manufacturer_name {
  display: block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 2px 10px 2px 10px;
  min-width: 50px;
  height: 20px;
  font-size: 14px;
}
ul.product_list > li .button-container .features_1 {
  display: block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 15px;
  font-size: 13px;
  font-weight: 100;
  color: #212121;
  line-height: 100%;
}
ul.product_list > li .button-container .features_2 {
  display: block;
  overflow: hidden;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  color: #212121;
}
ul.product_list > li .color-list-container {
  display: block;
  margin: 0px 0px 0px 0px;
  border: none;
  padding: 7px 0px 7px 0px;
  height: 40px !important;
}
ul.product_list.mobile > li .color-list-container, 
ul.product_list.tablet > li .color-list-container {
  margin: 3px 0px 3px 0px;
  height: 48px !important;
}
ul.product_list > li.hovered .color-list-container.no {
  display: none;
}
ul.product_list > li .color-list-container ul {
  display: inline-block;
  overflow: hidden;
  margin: 0px auto 0px auto;
  padding: 0px 2px 0px 2px;
  height: 26px !important;
}
ul.product_list.mobile > li .color-list-container ul, 
ul.product_list.tablet > li .color-list-container ul {
  padding: 0px 2px 0px 2px;
  height: 34px !important;
}
ul.product_list > li .color-list-container ul li {
  display: inline-block;
  float: left;
  border: 1px solid #d6d4d4;
  width: 26px !important;
  height: 26px !important;
}
ul.product_list.mobile > li .color-list-container ul li, 
ul.product_list.tablet > li .color-list-container ul li {
  width: 34px !important;
  height: 34px !important;
}
ul.product_list > li .color-list-container ul li a {
  display: block;
  margin: 1px 1px 1px 1px;
  width: 22px !important;
  height: 22px !important;
}
ul.product_list.mobile > li .color-list-container ul li a, 
ul.product_list.tablet > li .color-list-container ul li a {
  width: 30px !important;
  height: 30px !important;
}
ul.product_list > li .functional-buttons {
  display: none;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  height: 30px;
}
ul.product_list > li.hovered .functional-buttons, 
ul.product_list.mobile > li .functional-buttons, 
ul.product_list.tablet > li .functional-buttons {
  display: block;
}
ul.product_list > li .functional-buttons .add_to_compare {
  display: block;
  overflow: hidden;
  float: left;
  margin: 0px 0px 0px 0px;
  padding: 6px 6px 6px 6px;
  width: 100%;
  height: 30px;
  color: #212121;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
#categories_scenes ul.product_list > li .functional-buttons .add_to_compare {
  font-size: 13px;
  overflow: visible;
}
ul.product_list > li .functional-buttons .add_to_compare:hover {
  color: #212121;
}
ul.product_list > li .functional-buttons .add_to_compare:before {
  display: inline-block;
  font-family: "FontAwesome";
  content: "\f067";
  margin: 0px 3px 0px 0px;
}
#categories_scenes ul.product_list > li .functional-buttons .add_to_compare:before {
  display: none;
}
ul.product_list > li .functional-buttons .add_to_compare.checked:before {
  content: "\f068";
}
@media (min-width: 1200px) {
  ul.product_list > li {
    width: 20%;
    float: left;
  }
}
@media (max-width: 768px) {
  ul.product_list > li {
    float: left;
  }
  ul.product_list > li .product_name_product_list {
    height: 42px;
	font-size: 15px !important;
	line-height: 19px;
  }
  ul.product_list > li .product_name_product_list .product-name {
    height: 42px;
	font-size: 15px !important;
	line-height: 19px;
  }
  ul.product_list > li .collection_name_product_list {
    height: 42px;
	font-size: 15px !important;
	line-height: 19px;
  }
  ul.product_list > li .collection_name_product_list .kolekcja-nazwa {
    height: 42px;
	font-size: 15px !important;
	line-height: 19px;
  }
  ul.product_list > li .content_price {
    font-size: 18px !important;
  }
  ul.product_list > li .content_price .price {
    height: 18px;
    font-size: 18px !important;
  }
  ul.product_list > li .content_price .old-price {
    height: 21px;
    font-size: 13px !important;
  }
  ul.product_list > li .content_price .new-price-discount {
    height: 19px;
    font-size: 13px !important;
  }
  ul.product_list > li .button-container .manufacturer_name {
    height: 40px;
    font-size: 15px !important;
  }
  ul.product_list > li .button-container .features_1 {
    height: 16px;
    font-size: 14px !important;
  }
  ul.product_list > li .button-container .features_2 {
    height: 17px;
    font-size: 15px !important;
  }
  ul.product_list > li .functional-buttons {
    min-height: 40px;
  }
  ul.product_list > li .functional-buttons .add_to_compare {
    padding: 10px 6px 11px 6px;
	font-size: 15px !important;
	height: 40px;
  }
}
@media(min-width: 480px) and (max-width: 768px) {
  ul.product_list > li {
    width: 50%;
    float: left;
  }
  ul.product_list > li:nth-child(2n) {
	margin-bottom: 11px;
  }
}
@media (max-width: 480px) {
  ul.product_list.computer > li.ajax_block_product {
    margin: 5px 0px 0px 0px;
  }
  ul.product_list > li .product_image_container {
    margin: 0px auto 0px auto;
	max-width: 290px;
  }
  ul.product_list > li .product_name_product_list {
    height: 42px;
    font-size: 17px !important;
    line-height: 19px;
    font-weight: 400;
  }
  ul.product_list > li .product_name_product_list .product-name {
    height: 42px;
    font-size: 17px !important;
    line-height: 19px;
    font-weight: 400;
  }
  ul.product_list > li .collection_name_product_list {
	height: 42px;
    font-size: 19px !important;
    line-height: 19px;
  }
  ul.product_list > li .collection_name_product_list .kolekcja-nazwa {
    padding: 10px 2px 10px 2px;
	height: 42px;
    font-size: 19px !important;
    line-height: 19px;
  }
  ul.product_list > li .content_price {
    height: 50px;
  }
  ul.product_list > li .content_price .price {
    height: 26px;
    font-size: 25px !important;
    font-weight: 700;
    line-height: 25px;
  }
  ul.product_list > li .content_price .old-price {
    display: inline-block;
    margin: 0px 0px 0px 0px;
    height: 24px;
    font-weight: 700;
    line-height: 25px;
    font-size: 16px !important;
  }
  ul.product_list > li .content_price .new-price-discount {
    display: inline-block;
    margin: 0px 0px 0px 5px;
    padding: 0px 10px 0px 10px;
    height: 24px;
    line-height: 25px;
    font-size: 16px !important;
    font-weight: 600;
  }
  ul.product_list > li .button-container .manufacturer_name {
    padding: 10px 10px 10px 10px;
    height: 40px;
    font-size: 17px !important;
  }
  ul.product_list > li .button-container .features_1 {
    height: 20px;
    font-size: 18px;
  }
  ul.product_list > li .button-container .features_2 {
    height: 20px;
    font-size: 18px !important;
  }
  ul.product_list > li .functional-buttons {
    min-height: 40px;
  }
  ul.product_list > li .functional-buttons .add_to_compare {
    padding: 10px 6px 11px 6px;
	font-size: 15px !important;
	height: 40px;
  }
  ul.product_list > li {
    text-align: center;
  }
}