#cmsinfo_block {
  height: 344px;
}
@media (max-width: 992px) {
  #cmsinfo_block {
    height: 354px;
  }
}
#cmsinfo_block em {
  border-radius: 100px;
}
#cmsinfo_block .footer_h3 {
  margin: 0 0 5px;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
}
@media (max-width: 1200px) {
  #cmsinfo_block .footer_h3 {
    font-size: 18px;
  }
}
#cmsinfo_block {
  overflow: hidden;
  min-height: 390px;
  padding-right: 29px;
  padding-left: 29px;
}
@media (max-width: 992px) {
  #cmsinfo_block {
    min-height: 448px;
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media (max-width: 768px) {
  #cmsinfo_block {
    width: 100%;
    min-height: 1px;
  }
  #cmsinfo_block {
    border: none;
    margin-top: 0;
  }
}
#cmsinfo_block > div {
  padding: 35px 10px 0 0;
}
@media (max-width: 768px) {
  #cmsinfo_block > div {
    padding-top: 20px;
  }
}
#cmsinfo_block > div + div {
  border-left: 1px solid #d9d9d9;
  min-height: 390px;
  padding-left: 29px;
}
@media (max-width: 480px) {
  #cmsinfo_block > div {
    width: 100%;
    border-top: 0;
  }
  #cmsinfo_block > div + div {
    border-left: none;
    padding-left: 10px;
    min-height: 1px;
    padding-bottom: 15px;
  }
}
#cmsinfo_block em {
  float: left;
  width: 60px;
  height: 60px;
  margin: 3px 10px 0 0;
  font-size: 30px;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
}
@media (max-width: 992px) {
  #cmsinfo_block em {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
  }
}
#cmsinfo_block .type-text {
  overflow: hidden;
}
#cmsinfo_block h1 {
  margin: 0 0 5px;
  font-weight: 300;
  font-size: 21px;
  line-height: 25px;
  color: #212121;
}
#cmsinfo_block ul li {
  padding-bottom: 20px !important;
}
@media (max-width: 1200px) {
  #cmsinfo_block h1 {
    font-size: 18px;
  }
  #cmsinfo_block ul li {
    padding-bottom: 10px;
  }
  .zoomdiv {
    display: none !important;
  }
}
@media (max-width: 992px) {
  #cmsinfo_block ul li {
    padding-bottom: 0;
  }
}
#cmsinfo_block p em {
  background: 0 0;
  border-radius: 0;
  margin: 0px 0px 0px 0px;
  font-size: 13px;
  color: #212121;
  float: none;
  height: inherit;
  line-height: inherit;
  text-align: left;
  font-style: italic;
}
#cmsinfo_block em {
  background: #cecece;
}
#cmsinfo_block {
  background: #FFFFFF;
}
#cmsinfo_block .footer_h3,
#cmsinfo_block h4  {
  color: #212121;
  font-weight: 500;
}
#cmsinfo_block p {
  font-size: 13px;
  line-height: 18px;
}
#index.mobile #cmsinfo_block p {
  font-size: 16px;
  line-height: 21px;
}
#index.mobile #cmsinfo_block p a {
  padding: 0px 0px 0px 5px;
}
#cmsinfo_block .cmsinfo_b:nth-child(1) {
  float: left;
}
#cmsinfo_block .cmsinfo_b:nth-child(2) {
  float: none;
  width: auto;
  border: 0;
  padding-left: 0;
}
#cmsinfo_block .cmsinfo_b:nth-child(2) h1 {
  color: inherit;
  font-weight: 500;
  font-size: 21px;
}
#cmsinfo_block .cmsinfo_b:nth-child(2) h2 {
  font-size: 18px;
  margin-top: 10px;
}
#cmsinfo_block .cmsinfo_b:nth-child(2) p {
  margin-bottom: 0;
}
#cmsinfo_block {
  overflow: visible;
  height: auto;
  min-height: auto;
}
@media (max-width: 768px) {
  #cmsinfo_block .cmsinfo_b:nth-child(1) {
    float: none;
  }
  #cmsinfo_block .cmsinfo_b:nth-child(2) {
    width: 100%;
  }
}