#_desktop_top_menu {
  padding: 0px 0px 10px 0px;
  z-index: 0;
  position: relative;
  background-color: transparent !important;
}
#_desktop_top_menu #adtm_menu {
  clear: both;
  text-align: left;
  zoom: 1;
  position: relative !important;
  margin: 0px auto 0px auto !important;
  border: 0;
  padding: 0px 0px 0px 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#212121, Direction=137, Strength=0);
  z-index: 6;
  max-width: 1366px;
}
#_desktop_top_menu #adtm_menu #adtm_menu_inner {
  margin: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #F4A700;
  border-width: 0px 0px 1px 0px;
  padding: 0px 0px 0px 0px;
  /*width: inherit;*/
  background-color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu ul#menu {
  margin: 0px 0px 0px 0px;
  border-top: 1px solid #F4A700;
  padding: 0px 0px 0px 0px;
  list-style: none;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 {
  float: left;
  margin: 0px -1px 0px 0px;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 {
  display: block;
  float: left;
  border-style: solid;
  cursor: pointer;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 .advtm_menu_span {
  display: block;
}
#_desktop_top_menu #adtm_menu ul#menu li div.adtm_sub {
  position: absolute;
  top: 40px;
  left: -9999px;
  padding: 0;
  border-style: solid;
  box-sizing: border-box;
}
#_desktop_top_menu #adtm_menu .advtm_open_on_click ul#menu .li-niveau1.atm_clicked div.adtm_sub {
  left: 0;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title {
  display: block;
  text-align: left;
  border-bottom: 1px dotted #212121;
  line-height: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0px 0px 3px 0px;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements {
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 0px;
  list-style: none;
  zoom: 1;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements li {
  line-height: 19px;
  padding: 0;
  margin: 0;
  zoom: 1;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements li a {
  display: block;
  text-align: left;
}
#_desktop_top_menu #adtm_menu td.adtm_column_wrap_td {
  border-style: solid;
  border-width: 0;
}
#_desktop_top_menu #adtm_menu table.columnWrapTable {
  position: relative;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
#_desktop_top_menu #adtm_menu table.columnWrapTable tr td {
  padding: 0px 10px 0px 10px !important;
  vertical-align: top;
}
#_desktop_top_menu #adtm_menu .adtm_column_wrap_sizer {
  font-size: 0;
  height: 0;
  line-height: 0;
}
#_desktop_top_menu #adtm_menu img.adtm_menu_icon {
  vertical-align: top;
  margin: 0px 3px 0px 0px;
}
#_desktop_top_menu #adtm_menu .searchboxATM .search_query_atm {
  width: 150px;
  margin: 0px 0px 0px 0px!important;
}
#_desktop_top_menu #adtm_menu .searchboxATM .pbtn {
  display: inline-block;
}
#_desktop_top_menu #adtm_menu .search_query_atm.form-control {
  display: inline-block;
}
#_desktop_top_menu #adtm_menu .searchboxATM {
  position: relative;
}
#_desktop_top_menu #adtm_menu .adtm_search_submit_button {
  position: absolute;
  border: none;
  background: none;
  right: 0;
  top: 0;
  /*color: inherit;*/
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 a.adtm_unclickable {
  cursor: default;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 a.adtm_unclickable > span {
  cursor: default;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1 {
  min-height: 40px;
  line-height: 40px;
  border-color: #D6D4D4;
  border-width: 0px 0px 0px 0px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1.a-multiline {
  line-height: 24px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 {
  min-height: 40px;
  line-height: 40px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1 .advtm_menu_span {
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  min-height: 40px;
  line-height: 40px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1.a-multiline .advtm_menu_span {
  line-height: 24px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .searchboxATM {
  display: table-cell;
  height: 48px;
  vertical-align: middle;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .searchboxATM .adtm_search_submit_button {
  height: 48px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 a span {
  padding: 0px 20px 0px 20px;
  margin: 0px 0px 0px 0px;
}
#_desktop_top_menu #adtm_menu .li-niveau1 a.advtm_menu_actif .advtm_menu_span {
  background-color: #F4A700;
  color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .adtm_sub {
  border-color: #E5E5E5;
  border-width: 0px 1px 1px 1px;
  box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.2);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#212121, Direction=137, Strength=0);
  background-color: #FFFFFF;
  width: 100%;
  z-index: 16;
  box-shadow: none;
}
#_desktop_top_menu #adtm_menu ul#menu .li-niveau1 div.adtm_sub {
  border-top: 2px solid #F4A700;
  padding: 10px 0px 0px 0px;
  opacity: 1;
  visibility: visible;
}
#_desktop_top_menu #adtm_menu .adtm_column_wrap {
  padding: 0px 0px 0px 0px;
  overflow: auto;
  max-height: 750px;
}
#_desktop_top_menu #adtm_menu .adtm_column {
  padding: 0px 0px 0px 0px;
  margin: 0px 10px 0px 10px;
}
#_desktop_top_menu #adtm_menu .adtm_column_wrap span.column_wrap_title {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 3px 0px;
}
#_desktop_top_menu #adtm_menu .adtm_column span.column_wrap_title {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
}
#_desktop_top_menu #adtm_menu .adtm_column span.column_wrap_title a {
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
}
#_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li {
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
}
#_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li a {
  margin: 0px 0px 0px 0px;
  padding: 1px 0px 1px 0px;
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
}
#_desktop_top_menu #adtm_menu .advtm_hide_desktop {
  display: none!important;
}
#_desktop_top_menu #adtm_menu ul .advtm_menu_toggle {
  display: none;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1:first-child > a:after {
  border-left: 0;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 > a {
  position: relative;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 > a:after {
  content: '';
  border-left: 1px solid rgba(244, 167, 0, .5);
  position: absolute;
  z-index: 1;
  height: 10px;
  top: 15px;
  left: 0;
}
#_desktop_top_menu #adtm_menu ul#menu li.advtm_menu_toggle > a:after {
  border: 0;
}
#_desktop_top_menu #adtm_menu ul#menu > li.sub > a {
  position: relative;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a {
  font-weight: 700;
  font-size: 12px;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a > span {
  display: inline-block;
  margin: 6px 0px 5px 0px;
}
#_desktop_top_menu #adtm_menu #menu a.icon-home {
  color: #F4A700;
  padding: 0px 38px 0px 20px;
  font-size: 22px;
  min-height: 35px;
  line-height: 35px;
  width: 22px;
  display: inline-block;
}
#_desktop_top_menu #adtm_menu #menu a.icon-home:before {
  content: "\f015";
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap ul {
  margin: 0px 0px 10px 0px;
}
#_desktop_top_menu #adtm_menu .advtm_open_on_hover ul#menu .li-niveau1 div.adtm_sub {
  padding: 10px 0px 0px 0px!important;
}
#_desktop_top_menu #adtm_menu .adtm_sub .adtm_column {
  margin: 0px 0px 0px 0px;
}
#_desktop_top_menu #adtm_menu .menu_show_img img {
  position: absolute;
  top: 20px;
  right: 0;
  padding: 0px 50px 0px 50px;
}
#_desktop_top_menu #adtm_menu .menu_show_img {
  text-align: center;
}
#_desktop_top_menu #adtm_menu .menu_show_img .button-show-menu {
  cursor: pointer;
  text-align: center;
  background-color: #F4A700;
  display: inline-block;
  margin: 0px auto 0px auto;
  padding: 10px;
  color: #FFFFFF;
  margin: 50px 0px 0px 0px;
}
#_desktop_top_menu #adtm_menu nav#horizontal_menu .pr_column img {
  max-width: 100%;
  opacity: 0.8;
  margin: 0px 0px 5px 0px;
  transition: all 0.25s linear;
}
#_desktop_top_menu #adtm_menu .wrapper-img {
  position: absolute;
  top: 0;
  background-color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu #all_categories {
  position: absolute;
  left: 0;
  top: -10px;
  display: none;
  padding: 10px 0px 10px 0px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.2);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#212121, Direction=137, Strength=0);
}
#_desktop_top_menu #adtm_menu #all_categories:after {
  content: 'x';
  position: absolute;
  color: #FFFFFF;
  background-color: #F4A700;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0px 10px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 19px;
  line-height: 30px;
}
#_desktop_top_menu #adtm_menu .pr_column ul li {
  line-height: 20px;
}
#_desktop_top_menu #adtm_menu .pr_column ul li a {
  transition: all 0.25s linear;
  padding: 0 2px 0 0;
  display: inline-block;
  position: relative;
  font-weight: normal;
  line-height: 20px;
}
#_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a {
  display: block;
  padding: 0px 0px 10px 0px;
}
#_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a img {
  float: right;
  margin: 0px 50px 0px 0px;
  width: 50px;
  height: 28px;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1:first-child {
  position: relative;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1:first-child:after {
  right: -1px;
}
#_desktop_top_menu #adtm_menu .advtm_role_manufacturer div.adtm_column_wrap span.column_wrap_title {
  border: 0;
}
#_desktop_top_menu #adtm_menu #adtm_open_menu {
  height: 51px;
  width: 100%;
  display: block;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 2px solid #F4A700;
  border-bottom: 1px solid #F4A700;
}
#_desktop_top_menu #adtm_menu .toggle-menu-bis {
  display: block;
  float: left;
  border-style: solid;
  width: 100%;
  cursor: pointer;
}
#_desktop_top_menu #adtm_menu .menu_logo {
  float: left;
}
#_desktop_top_menu #adtm_menu .toggle-menu-bis span {
  font-size: 16px;
  min-height: 48px;
  line-height: 40px;
  padding: 5px 20px 0px 10px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  background-position: right 15px center;
  background-repeat: no-repeat;
  float: right;
}
#_desktop_top_menu #adtm_menu .toggle-menu-bis span:after {
  content: "\f0c9";
  font-family: "FontAwesome";
  display: inline-block;
  font-size: 23px;
  line-height: 23px;
  color: #F4A700;
  width: 20px;
  height: 23px;
}
#_desktop_top_menu #adtm_menu .toggle-menu-bis img {
  margin: 10px 0px 0px 0px;
}
#_desktop_top_menu #adtm_menu .menu-buttons > span > a > i {
  font-size: 24px;
}
@media (max-width: 1200px) {
  #_desktop_top_menu #adtm_menu .li-niveau1 a span {
    padding: 0px 10px 0px 20px !important;
    margin: 0px 0px 0px 0px !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a img {
    margin: 0px 25px 0px 0px !important;
  }
}
@media (max-width: 1080px) {
  #_desktop_top_menu #adtm_menu .menu_show_img img {
    width: 500px !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a img {
    float: none !important;
    margin: 0px auto 0px auto !important;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a {
    text-align: center !important;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a > span {
    display: inline-block;
	margin: 6px 0px 0px 0px !important;
	padding: 0px 0px 0px 0px !important;
    text-align: center !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a {
    padding: 0px 0px 15px 0px !important;
  }
}
@media (max-width: 992px) {
  #_desktop_top_menu #adtm_menu .menu_show_img img {
    width: 500px !important;
  }
}
@media (max-width: 768px) {
  #header #_desktop_top_menu {
    display: none;
  }
  #_desktop_top_menu {
    overflow: auto;
    position: fixed;
	top: 0;
	z-index: 8 !important;
	margin: 0px 0px 10px 0px;
	padding: 0px 0px 0px 0px;
	width: 100%;
  }
  #_desktop_top_menu #adtm_menu {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
  }
  #_desktop_top_menu #adtm_menu .advtm_hide_mobile {
    display: none!important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.advtm_search.advtm_hide_mobile {
    display: none!important;
  }
  #_desktop_top_menu #adtm_menu .a-niveau1 {
    height: auto !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_menu_span {
    height: auto !important;
  }
  #_desktop_top_menu #adtm_menu ul li.li-niveau1 {
    display: none;
  }
  #_desktop_top_menu #adtm_menu ul li.advtm_menu_toggle {
    display: block;
    border: 1px solid #ccc;
	border-bottom: 2px solid #F4A700;
	width: 100%;
    height: 51px;
  }
  #_desktop_top_menu #adtm_menu ul li.advtm_menu_toggle .adtm_toggle_menu_button {
    cursor: pointer;
    font-size: 16px;
    line-height: 40px;
    min-height: 48px;
    width: 50px;
  }
  #_desktop_top_menu #adtm_menu .adtm_menu_icon {
    margin: 12px auto 0px auto !important;
	height: auto;
    max-width: 100%;
  }
  #_desktop_top_menu #adtm_menu ul .li-niveau1 .adtm_sub {
    height: auto;
    /*min-height: inherit;*/
    background-color: #FFFFFF;
    border-color: #E5E5E5;
    border-width: 0px 1px 0px 1px;
  }
  #_desktop_top_menu #adtm_menu ul div.adtm_column_wrap {
    /*min-height: inherit;*/
    width: 100% !important;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1 .advtm_menu_span {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  #_desktop_top_menu #adtm_menu .adtm_column span.column_wrap_title {
    display: block;
	font-size: 18px;
    font-weight: bold;
    text-transform: none;
  }
  #_desktop_top_menu #adtm_menu .adtm_column span.column_wrap_title a {
    display: block;
	font-size: 18px;
    font-weight: bold;
    text-transform: none;
  }
  #_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li {
    font-weight: normal;
    text-transform: none;
    height: 51px;
	font-size: 18px;
  }
  #_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li a {
    margin: 15px 0px 15px 0px;
    padding: 5px 0px 5px 10px;
    font-size: 18px;
    font-weight: normal;
    text-transform: none;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1.adtm_sub_open .a-niveau1 .advtm_menu_span {
    /*color: #FFFFFF;*/
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub .a-niveau1 span {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAFVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAASAQCkAAAABnRSTlMAHiXy6t8iJwLjAAAANUlEQVQY02MgFwgisZmMFZA4Zo5IUiLJSFKMbkZESqUoYKjDNFw5RYAYCSckW0IEULxAPgAAZQ0HP01tIysAAAAASUVORK5CYII=');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background: none;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub.adtm_sub_open .a-niveau1 span {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAFVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAASAQCkAAAABnRSTlMAHiXy6t8iJwLjAAAARUlEQVQY02OgKWBUAJFMYJJB1AhEChuCOSLJCkBpNxAHRBsBRVIUIJpUkhVgEmAlIKVgAFIDUgmXgkmAzXWCMqA20hgAAI+xB05evnCbAAAAAElFTkSuQmCC');
	background-image: none;
    background-repeat: no-repeat;
    background-position: right 15px center;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 a span {
    margin: 0px 0px 0px 0px !important;
    padding: 5px 0px 5px 0px !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_menu_actif {
    background-color: #F4A700;
  }
  #_desktop_top_menu #adtm_menu .advtm_menu_actif ul {
    background-color: #FFFFFF;
  }
  #_desktop_top_menu #adtm_menu .adtm_column_wrap {
    margin: 0px 0px 0px 0px;
	padding: 0px 0px 0px 0px;
    max-height: none;
  }
  #_desktop_top_menu #adtm_menu .adtm_column_wrap_td {
    border-color: #E5E5E5;
    border-width: 0px 0px 1px 0px;
  }
  #_desktop_top_menu #adtm_menu .adtm_column {
    margin: 0px 10px 5px 10px;
    padding: 0px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu .adtm_column_wrap span.column_wrap_title {
    padding: 0px 0px 0px 0px;
    margin: 8px 10px 8px 0px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu .advtm_hide_desktop {
    display: block !important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1 {
    display: block !important;
	float: none;
	margin: 0px 0px 0px 0px;
    height: 51px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.advtm_hide_mobile {
    display: none !important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.advtm_menu_toggle.adtm_menu_mobile_mode {
    display: none !important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1 .a-niveau1 {
    float: none;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 {
    border-color: #D6D4D4;
    border-width: 0px 0px 1px 0px !important;
	padding: 0px 0px 0px 0px;
	text-align: left;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li div.adtm_sub {
    display: none;
    position: static;
    height: auto;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li div.adtm_sub.adtm_submenu_toggle_open {
    display: block;
	overflow: auto;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open table.columnWrapTable {
    display: table !important;
    width: 100% !important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open table.columnWrapTable tr td {
    display: block;
    padding: 0px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.advtm_search .searchboxATM {
    display: flex;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.advtm_search .searchboxATM .search_query_atm {
    padding: 15px 5px;
    width: 100%;
  }
  #_desktop_top_menu #adtm_menu #menu a.icon-home:before {
    margin: 0px 5px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu .menu_logo a {
    display: inline-block;
    padding: 3px 0px 3px 0px;
    height: 48px;
  }
  #_desktop_top_menu #adtm_menu span.menu-basket.open span.count-products {
    background-color: #212121;
  }
  #_desktop_top_menu #adtm_menu .menu-basket {
    position: relative;
  }
  #_desktop_top_menu #adtm_menu .menu-basket .count-products {
    position: absolute;
    display: inline-block;
    line-height: 15px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDB4rn+FCkAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/iynBEABC8BnSLN56YAAAAASUVORK5CYII=') repeat;
    border-radius: 15px;
    font-size: 12px;
    width: 15px;
    color: #FFFFFF;
    top: 6px;
    right: 7px;
    text-align: center;
    cursor: pointer;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a > span {
    display: inline-block;
	margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    text-align: left;
  }
  #_desktop_top_menu #adtm_menu .open {
    color: #F4A700;
  }
  #_desktop_top_menu #adtm_menu .open a {
    color: #F4A700;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.advtm_role_panorama span.column_wrap_title {
    padding: 5px 10px 5px 10px !important;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.advtm_role_panorama span.column_wrap_title a {
    padding: 0px 0px 0px 20px;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .adtm_menu_icon {
    margin: 12px 30px 0px auto;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a img {
    float: right !important;
    margin: 12px 30px 0px 0px !important;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a {
    text-align: left !important;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
  }
  #_desktop_top_menu #adtm_menu .menu_show_img {
    display: none !important;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1:first-child {
    display: none !important;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1:after {
    border: 0;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub .a-niveau1.advtm_menu_actif span {
    background-image: none;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.sub span.column_wrap_title {
    display: block;
    border: 0;
    margin: 0px 0px 0px 0px;
	border-bottom: 1px solid #ccc;
	padding: 5px 10px 5px 20px;
    overflow: hidden;
    min-height: 40px;
    line-height: 40px;
	height: 51px;
  }
  #_desktop_top_menu #adtm_menu li.sub.advtm_role_full_preview span.column_wrap_title {
    position: relative;
    cursor: pointer;
  }
  #_desktop_top_menu #adtm_menu li.sub.advtm_role_full_preview span.column_wrap_title:after {
    font-family: "FontAwesome";
    position: absolute;
    top: 7px;
    right: 20px;
    color: #F4A700;
  }
  #_desktop_top_menu #adtm_menu #adtm_menu_inner {
    border: 0;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 {
    min-height: 41px;
  }
  #_desktop_top_menu #adtm_menu ul#menu .li-niveau1 div.adtm_sub {
    border: 0;
  }
  #_desktop_top_menu #adtm_menu table.columnWrapTable tr td {
    padding: 0px 0px 0px 0px !important;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements li a {
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid #D6D4D4;
	padding: 15px 30px;
	height: 51px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.sub.advtm_menu_7 span.column_wrap_title {
    height: auto;
  }
  #_desktop_top_menu #adtm_menu .adtm_submenu_toggle_open {
    height: 220px;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements {
    margin: 0px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu .advtm_open_on_hover ul#menu .li-niveau1 div.adtm_sub {
    padding: 0px 0px 0px 0px !important;
  }
  #_desktop_top_menu #adtm_menu .advtm_role_manufacturer .column_wrap_title a {
    padding: 0px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.advtm_role_manufacturer span.column_wrap_title {
    margin: 0px 0px 0px 0px;
	padding: 0px 10px 0px 20px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1 .adtm_toggle_menu_button {
    float: right;
    width: 50px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons {
	display: inline-block;
    float: right;
    line-height: 50px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open .menu-buttons {
    display: none;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .adtm_toggle_menu_button {
	float: right;
	border: none;
    padding: 5px 0px 0px 10px;
	font-weight: 700;
  }
  #_desktop_top_menu #adtm_menu .menu-logo {
    display: inline-block;
    float: left;
  }
  #_desktop_top_menu #adtm_menu .menu-logo img {
    margin: 0px 0px 0px 15px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span {
    display: inline-block !important;
	width: 50px;
	height: 48px;
	cursor: pointer;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a {
    display: inline-block;
	width: 50px;
	height: 48px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a > i {
    font-size: 20px;
    display: inline-block;
    text-align: center;
    width: 50px;
    height: 48px;
    line-height: 47px;
  }
  #_desktop_top_menu #adtm_menu .menu-login .icon-user:before {
	content: "\f007";
  }
  #_desktop_top_menu #adtm_menu .menu-search .icon-search:before {
    content: "\f002";
  }
  #_desktop_top_menu #adtm_menu .menu-basket .icon-cart-plus:before {
	content: "\f217";
  }
  #_desktop_top_menu #adtm_menu .menu-email .icon-phone:before {
	content: "\f095";
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open li.advtm_menu_toggle .adtm_toggle_menu_button {
    background-color: transparent;
  }
  #_desktop_top_menu #adtm_menu ul li.advtm_menu_toggle .adtm_toggle_menu_button:after {
    content: "\f0c9";
    font-family: "FontAwesome";
    display: block;
	margin: 6px 0px 0px 0px;
    font-size: 23px;
    line-height: 23px;
    color: #F4A700;
    width: 20px;
	height: 23px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 .advtm_menu_span {
    padding: 5px 0px 5px 10px !important;
  }
  #_desktop_top_menu #adtm_menu .toggle-menu-bis img {
    width: 160px;
    height: 30px;
    margin: 10px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 > i:after {
    font-weight: bold;
  }
  #_desktop_top_menu #adtm_menu .menu_plus {
    display: block;
	float: right;
	position: relative;
    top: 0px;
    right: 0px;
	z-index: 2;
	border-bottom: solid 1px #D6D4D4;
    height: 51px;
    width: 40px;
	font-style: normal;
	font-weight: 600;
  }
  #_desktop_top_menu #adtm_menu .menu_plus:after {
    display: block;
    font-family: "FontAwesome";
    content: "\f067";
    font-size: 18px;
    color: #F4A700;
    box-sizing: border-box;
    cursor: pointer;
    margin: 7px 0px 0px 12px;
  }
  #_desktop_top_menu #adtm_menu .menu_minus {
    display: block;
	float: right;
	position: relative;
    top: 0px;
    right: 0px;
    z-index: 2;
	border-bottom: solid 1px #D6D4D4;
    height: 51px;
    width: 40px;
	font-style: normal;
	font-weight: 600;
  }
  #_desktop_top_menu #adtm_menu .menu_minus:after {
    display: block;
    font-family: "FontAwesome";
    content: "\f068";
    font-size: 18px;
    color: #F4A700;
    box-sizing: border-box;
    cursor: pointer;
    margin: 7px 0px 0px 12px;
  }
  #_desktop_top_menu #adtm_menu ul#menu > li > a {
    position: static !important;
  }
}
@media (max-width: 450px) {
  #_desktop_top_menu #adtm_menu .menu-buttons > span {
    width: 35px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a {
    width: 35px;
  } 
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a > i {
    width: 35px;
  }
}
@media (max-width: 380px) {
  #_desktop_top_menu #adtm_menu .menu_logo a img {
    width: 120px;
    height: 23px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1 .adtm_toggle_menu_button {
    float: right;
    width: 50px;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements li a {
    height: 41px;
	padding: 10px 25px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.sub span.column_wrap_title {
    height: 41px;
	font-size: 14px;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1 .advtm_menu_span {
    font-size: 14px;
  }
  #_desktop_top_menu #adtm_menu div.adtm_column_wrap span.column_wrap_title a {
    font-size: 14px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 .advtm_menu_span {
    padding: 0px 0px 0px 10px;
  }
  #_desktop_top_menu #adtm_menu .toggle-menu-bis img {
    width: 120px;
    height: 23px;
    margin: 10px 0px 0px 0px;
  }
  #_desktop_top_menu #adtm_menu ul#menu li.li-niveau1 .a-niveau1 .adtm_toggle_menu_button {
    padding: 5px 0px 0px 10px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub .a-niveau1 span {
    padding: 0px 10px 0px 10px
  }
  #_desktop_top_menu #adtm_menu ul#menu li.sub span.column_wrap_title {
    padding: 0px 10px 0px 15px;
  }
  #_desktop_top_menu #adtm_menu.adtm_menu_toggle_open ul#menu li.li-niveau1.sub .a-niveau1 span:after {
    top: 1px;
  }
  #_desktop_top_menu #adtm_menu li.sub.advtm_role_full_preview span.column_wrap_title:after {
    top: 1px;
  }
  #_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li {
    font-size: 14px;
  }
  #_desktop_top_menu #adtm_menu .adtm_column ul.adtm_elements li a {
    font-size: 14px;
  }
  #_desktop_top_menu #adtm_menu .menu_plus {
    height: 41px;
  }
  #_desktop_top_menu #adtm_menu .menu_plus:after {
    margin: 1px 0px 0px 12px;
  }
  #_desktop_top_menu #adtm_menu .menu_minus {
    height: 41px;
  }
  #_desktop_top_menu #adtm_menu .menu_minus:after {
    margin: 1px 0px 0px 12px;
  }
}
@media (max-width: 345px) {
  #_desktop_top_menu #adtm_menu .menu-buttons > span {
    width: 23px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a {
    width: 23px;
  }
  #_desktop_top_menu #adtm_menu .menu-buttons > span > a > i {
    width: 23px;
  }
}
@media (max-width: 300px) {
  #_desktop_top_menu #adtm_menu .menu-buttons {
    display: none;
  }
}

#_desktop_top_menu #adtm_menu .advtm_open_on_hover ul#menu .li-niveau1:hover div.adtm_sub {
  left: 0;
}
#_desktop_top_menu #adtm_menu .advtm_open_on_hover ul#menu a:hover div.adtm_sub {
  left: -1px;
  top: 29px;
}
#_desktop_top_menu #adtm_menu .advtm_open_on_click ul#menu .atm_clicked a:hover div.adtm_sub {
  left: -1px;
  top: 29px;
}
#_desktop_top_menu #adtm_menu ul#menu .li-niveau1:hover div.adtm_sub {
  opacity: 1;
  visibility: visible;
  transition: none;
}
#_desktop_top_menu #adtm_menu .li-niveau1 a:hover .advtm_menu_span {
  background-color: #F4A700;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .advtm_menu_span:hover {
  background-color: #F4A700;
}
#_desktop_top_menu #adtm_menu .li-niveau1:hover > .a-niveau1 .advtm_menu_span {
  background-color: #F4A700;
  color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1:hover .advtm_menu_span {
  color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu div.adtm_column_wrap ul.adtm_elements li a:hover {
  background-color: #F4A700;
}
#_desktop_top_menu #adtm_menu #menu > li:first-child:hover {
  background-color: #F4A700;
}
#_desktop_top_menu #adtm_menu #menu > li:first-child:hover a:before {
  color: #FFFFFF;
}
#_desktop_top_menu #adtm_menu ul#menu li.li-niveau1:hover:after {
  border: 0;
}
#_desktop_top_menu #adtm_menu .pr_column ul li a:hover {
  background-color: #F4A700;
  padding: 0px 0px 0px 5px !important;
}
@media (max-width: 768px) {
  #_desktop_top_menu #adtm_menu .li-niveau1:hover > .a-niveau1 .advtm_menu_span {
    color: #212121 !important;
  }
  #_desktop_top_menu #adtm_menu .li-niveau1 .a-niveau1:hover .advtm_menu_span {
    background: none !important;
	color: #212121 !important;
  }
  #_desktop_top_menu #adtm_menu ul#menu .li-niveau1:focus div.adtm_sub {
    transition: none;
  }
  #_desktop_top_menu #adtm_menu ul#menu .li-niveau1:hover div.adtm_sub {
    transition: visibility 0s linear 0s, opacity 0s linear 0s;
  } 
}
