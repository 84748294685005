#shopping_cart {
  display: block;
  position: relative;
  top: 0px;
  z-index: 1;
  float: right;
  padding: 10px 15px 0px 10px;
  height: 46px;
}
#shopping_cart > a {
  display: block;
  overflow: hidden;
  padding: 4px 7px 14px 16px;
  height: 36px;
  font-weight: 600;
  color: #212121;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH4wEEDB4rn+FCkAAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY/iynBEABC8BnSLN56YAAAAASUVORK5CYII=')
    repeat;
}
#shopping_cart > a:before {
  display: inline-block;
  font-family: "FontAwesome";
  content: "\f07a";
  font-size: 23px;
  line-height: 23px;
  color: #FFFFFF;
  padding-right: 15px;
}
#shopping_cart > a:after {
  display: inline-block;
  float: right;
  font-family: "FontAwesome";
  content: "\f0d7";
  font-size: 18px;
  color: #212121;
  padding: 6px 0 0 0;
}
#shopping_cart > a:hover:after {
  content: "\f0d8";
  padding: 4px 0 2px 0;
}
#shopping_cart > a:hover {
  outline: none !important;
  text-decoration: none !important;
  box-shadow: none !important;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY3i8SBcABB4Bs1pBa5sAAAAASUVORK5CYII=') !important;
}
#shopping_cart > a > b {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  padding-right: 5px;
}
#shopping_cart .remove_link:after {
  display: inline-block;
  font-family: FontAwesome; 
  content: '\f068'; 
  font-size: 18px; 
}
@media (max-width: 768px) {
  #shopping_cart {
	display: none;
	position: fixed;
	top: 52px;
	margin: 0px 0px 0px 0px !important;
	padding: 0px 0px 0px 0px !important;
    width: 100%;
  }
  #shopping_cart.expand {
    display: inline-table;
  }
  #shopping_cart > a {
    display: none;
  }
}